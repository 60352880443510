import React from "react"
import { FaMountain } from "react-icons/fa"
import Colors from "../../constants/Colors"
import { Mountain } from "../../models/NationalPark"
import CustomCard from "../cards/CustomCard"
import ExploreAlpine from "./ExploreAlpine"

interface Props {
  title: string
  mountain: Mountain
}

export default function MountainSidebar({ title, mountain }: Props) {
  return (
    <div className="col-span-12 md:col-span-4 pr-6">
      <CustomCard className="pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-2 ml-0 md:ml-0">
        <h4 className="mt-0 mb-3">{title}</h4>
        {mountain.mountain_elevation !== undefined && (
          <div className="bg-lightest rounded-sm pt-2 pb-1 px-2">
            <p className="inline-block mt-0 mb-0 align-text-bottom text-sm">
              Elevation
            </p>
            <p className="inline-block mt-0 mb-0 align-text-bottom text-sm float-right w-28 font-bold">
              <FaMountain
                size={14}
                className="inline-block"
                color={Colors.themeColor}
                style={{ marginBottom: 2, marginRight: 5 }}
              />
              {mountain.mountain_elevation} meters
            </p>
          </div>
        )}
      </CustomCard>
      <ExploreAlpine title={"Alpine Parks"} />
    </div>
  )
}
