import React from "react"
import Colors from "../../constants/Colors"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Routes from "../../constants/Routes"

interface Props {
  title: string
}

export default function ExploreAlpine({ title }: Props) {
  return (
    <Link to={Routes.TAG_ALPINE} title={`Alpine National Parks`}>
      <div className="md:mr-6 mt-4 mr-0">
        <div className="img-hover-zoom rounded" style={{ display: "grid" }}>
          <StaticImage
            src="../../images/sidebar/mountain-sidebar.jpg"
            alt="Alpine National Parks"
            placeholder="blurred"
            className="w-full object-cover"
            style={{
              gridArea: "1/1",
            }}
          />
          <div
            style={{
              gridArea: "1/1",
              position: "relative",
              display: "grid",
            }}
          >
            <h3 className="ml-4 mb-4 text-white">{title}</h3>
            <span className="absolute text-sm text-light ml-4 mt-14">
              Explore the highest peaks
            </span>
          </div>
        </div>
      </div>
    </Link>
  )
}
